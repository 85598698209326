import { useCustomizationContext } from '@apps/card/routes/CardCustomizer';

const useGetCardDimensions = () => {
  const [layout] = useCustomizationContext(draft => draft.config.card.front.dimensions ?? draft.config.card.layout);
  const { width, height } = layout;
  const prefix = '';

  if (width && height) {
    return {
      width,
      height,
      prefix
    };
  }

  return {
    width: 5,
    height: 7,
    prefix
  };
};

export default useGetCardDimensions;
