import React, { useMemo } from 'react';
import { Layout } from '@apps/card/components/Layout';
import PriceSummary, { PriceUpTo } from '@apps/card/components/PriceSummary';
import { useCustomizationContext } from '../../useCardCustomization';
import ReceiptBlock from '@apps/card/components/Blocks/ReceiptBlock';
import { useNavigationContext } from '../../useNavigationState';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { NavigationStep, PrintNavigationStep, ThemeJson } from '../../CardCustomizer.types';
import { CardPaymentMethodFragment, StationeryDraftFormat } from '@graphql/generated';
import { Box, TextV2 } from '@withjoy/joykit';
import { capitalize } from '@shared/joykit/packages/core/utils';
import { templateToTitle } from '@apps/card/Card.utils';
import { useCardCustomizerTelemetry } from '../../CardCustomizer.telemetry';
import { PromoCodeInput } from '../PromoCodeInput';
import { usePromotionConfig } from '../../usePromotionConfig';
import { CardPromotion } from '@apps/card/components/PriceSummary/PriceSummary.controller';
import useGetCardDimensions from '@apps/card/routes/Dashboard/components/ProductDetailsPage/useGetCardDimensions';
import { useCardCustomizerConfig } from '@apps/card/hooks/useCardCustomizedConfig';
import { useStationeryTemplateCategoryTranslations } from '@apps/card/hooks/useStationeryTemplateCategoryTranslations';

interface SidePanelPriceBreakdownProps {
  title?: string;
  paidWith?: CardPaymentMethodFragment | null;
  config?: ThemeJson;
  disablePromoCodeInput?: boolean;
  promotionOverride?: Maybe<CardPromotion>;
  taxInMinorUnits?: Maybe<number>;
}

const VISIBILITY_CONFIG: Record<PriceUpTo, Partial<Record<PriceUpTo, boolean>>> = {
  cardFront: {
    cardFront: true
  },
  cardBack: {
    cardFront: true,
    cardBack: true
  },
  envelope: {
    cardFront: true,
    cardBack: true,
    envelope: true
  },
  total: {
    cardFront: true,
    cardBack: true,
    envelope: true,
    total: true
  }
};

export const SidePanelPriceBreakdownBlock = (props: SidePanelPriceBreakdownProps) => {
  const { disablePromoCodeInput, promotionOverride, taxInMinorUnits } = props;
  const [config] = useCustomizationContext(draft => draft.config);
  const [templateCategory] = useCustomizationContext(draft => draft.stationeryTemplateCategory);
  const [template] = useCustomizationContext(draft => draft.template);
  const getCategoryName = useStationeryTemplateCategoryTranslations('stationeryTemplateCategoryToTile');
  const categoryName = getCategoryName(templateCategory);
  const { goToStep, currentStep, steps } = useNavigationContext();
  const { customizationButtonInteracted } = useCardCustomizerTelemetry();
  const { currentPromotion, isEnabled: isCustomPromoEnabled } = usePromotionConfig();
  const { helpers } = useCardCustomizerConfig(StationeryDraftFormat.print, templateCategory);

  const priceUpTo = useMemo((): PriceUpTo => {
    // Reversing the keys to find the last completed step
    const lastCompletedStep = (Object.keys(steps) as Array<PrintNavigationStep>).reverse().find(step => {
      // Need to check if the step is the current step or if it has been completed
      return step === currentStep || steps[step].hasCompleted;
    });

    const target = lastCompletedStep || currentStep;

    switch (target) {
      case 'cardFront':
        return 'cardFront';
      case 'cardBack':
        return 'cardBack';
      case 'envelope':
        return 'envelope';
      case 'review':
        return 'total';
      default:
        return 'total';
    }
  }, [currentStep, steps]);

  const visibiltyConfig = VISIBILITY_CONFIG[priceUpTo];

  const handleOnEditClick = useEventCallback((step: 'cardFront' | 'cardBack' | 'envelope') => {
    customizationButtonInteracted({ property: 'orderSummaryBlock_goToStep', value: step });
    goToStep(step);
  });

  const isContainedDesign = currentStep === 'review' || currentStep === 'postCheckout';
  const canMakeEdits = currentStep !== 'postCheckout';
  const isCurrentStep = (step: NavigationStep) => step === currentStep;
  const cardDimensions = useGetCardDimensions();

  return (
    <Layout.SidePanelBlock backgroundColor={isContainedDesign ? undefined : 'mono2'} paddingY={isContainedDesign ? undefined : 7} marginTop={'auto'}>
      <PriceSummary
        salesTax={taxInMinorUnits}
        templateCategory={templateCategory}
        title={typeof props.title === 'undefined' ? 'Order Summary' : props.title}
        cardConfig={props.config || config}
        priceUpTo={priceUpTo}
        promotion={promotionOverride ?? currentPromotion}
        cardSize={cardDimensions}
      >
        {summary => (
          <>
            {visibiltyConfig.cardFront && (
              <ReceiptBlock
                title={categoryName}
                subTitle={templateToTitle(template)}
                onEditButtonClick={maybeApplyCallback(canMakeEdits && !isCurrentStep('cardFront'), () => handleOnEditClick('cardFront'))}
                lines={summary.card}
              />
            )}
            {visibiltyConfig.cardBack && (
              <ReceiptBlock
                title="Back"
                onEditButtonClick={maybeApplyCallback(canMakeEdits && !isCurrentStep('cardBack'), () => handleOnEditClick('cardBack'))}
                lines={summary.back}
              />
            )}
            {visibiltyConfig.envelope && helpers.isStepEnabled('envelope') && (
              <ReceiptBlock
                title="Envelope"
                onEditButtonClick={maybeApplyCallback(canMakeEdits && !isCurrentStep('envelope'), () => handleOnEditClick('envelope'))}
                lines={summary!.envelope}
              />
            )}
            {visibiltyConfig.total && <ReceiptBlock title="Shipping Method" lines={summary!.shipping} />}
            {!!taxInMinorUnits && <ReceiptBlock title="Tax" lines={summary.tax} />}
          </>
        )}
      </PriceSummary>
      {!disablePromoCodeInput && isCustomPromoEnabled && (
        <Box marginTop={7}>
          <PromoCodeInput />
        </Box>
      )}
      {props.paidWith && (
        <TextV2 typographyVariant="label2">
          Paid with {capitalize(props.paidWith.card?.brand).text} {props.paidWith.card?.last4}
        </TextV2>
      )}
    </Layout.SidePanelBlock>
  );
};
